<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <div style="line-height: 10px">
        <h4>Available</h4>
        <span class="text-muted">Empower Company</span>
      </div>
      <b-button
        variant="primary"
        class="rounded-pill"
        size="sm"
        v-b-modal.add-position
      >
        New Position
      </b-button>
    </div>
    <b-row>
      <b-col
        md="3"
        v-for="position in teams"
        :key="position.id"
        @click="show_detail(position)"
        class="cursor-pointer"
      >
        <b-card class="mt-2">
          <b-card-body align="center">
            <b-avatar :src="position.icon" size="50"></b-avatar>
            <b-card-text class="mt-2">
              <h4>{{ position.name }}</h4>
              <span>{{ position.user_count }} People Assigned</span>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <div class="d-flex justify-content-between align-items-center">
      <span>
        Showing
        {{ perPage > totalRows ? totalRows : perPage }} of
        {{ totalRows }} entries
      </span>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
        size="sm"
        class="my-0"
        @change="pageChange"
      />
    </div>

    <b-modal id="add-position" title="Add New Position" size="md" hide-footer>
      <form @submit.prevent="submit_form">
        <b-form-group label="department">
          <b-form-select v-model="department" :options="departments" />
        </b-form-group>
        <b-form-group>
          <b-form-input
            v-model="name"
            type="text"
            placeholder="Position Name"
          />
        </b-form-group>
        <b-form-group label="Icon">
          <b-form-file
            v-model="icon"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
        <div class="text-right mt-1">
          <b-button type="submit" variant="primary" size="sm"> Add </b-button>
        </div>
      </form>
    </b-modal>

    <b-modal id="update_position" title="Update Position" size="md" hide-footer>
      <form @submit.prevent="form_update(position)">
        <b-form-group>
          <b-form-input
            v-model="position.name"
            type="text"
            placeholder="Position Name"
          />
        </b-form-group>
        <div class="text-right mt-1">
          <b-button
            variant="outline-danger"
            class="mr-1"
            size="sm"
            @click="deleteD(position.id)"
          >
            Delete
          </b-button>
          <b-button type="submit" variant="primary" size="sm">
            Update
          </b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      teams: [],
      departments: [],
      name: "",
      department: "",
      icon: "",

      perPage: 8,
      totalRows: 1,
      currentPage: 1,

      position: {},
    };
  },
  mounted() {
    this.callApi({
      method: "GET",
      url: "/teams/fetch",
      params: {
        limit: 8,
        assigned: true,
      },
      success: (response) => {
        this.teams = response.result.data;
        this.totalRows = response.result.total;
        this.currentPage = response.result.current_page;
        this.perPage = response.result.per_page;
      },
    });

    this.callApi({
      method: "GET",
      url: "/departments/fetch",
      success: (response) => {
        response.result.forEach((department) => {
          this.departments.push({
            value: department.id,
            text: department.name,
          });
        });
      },
    });
  },
  methods: {
    show_detail(data) {
      this.position = data;
      this.$bvModal.show("update_position");
    },

    pageChange(page) {
      this.callApi({
        method: "GET",
        url: "/teams/fetch",
        params: {
          limit: 8,
          page: page,
          assigned: true,
        },
        success: (response) => {
          this.teams = response.result.data;
          this.totalRows = response.result.total;
          this.currentPage = response.result.current_page;
          this.perPage = response.result.per_page;
        },
      });
    },

    deleteD(id) {
      this.callApi({
        method: "DELETE",
        url: "/teams/delete/" + this.hashid(id),
        success: (response) => {
          this.teams = this.teams.filter((department) => department.id != id);

          this.$bvModal.hide("detail_" + id);
        },
      });
    },

    form_update(data) {
      this.callApi({
        method: "POST",
        url: `/teams/update/${this.hashid(data.id)}`,
        data: {
          name: data.name,
        },
        success: (response) => {
          this.levels.push(response.result);
          this.$bvModal.hide(`detail_${data.id}`);
        },
      });
    },

    submit_form() {
      const formData = new FormData();
      formData.append("icon", this.icon);
      formData.append("department_id", this.department);
      formData.append("name", this.name);

      this.callApi({
        method: "POST",
        url: "/teams/create",
        data: formData,
        success: (response) => {
          this.teams.push(response.result);
          this.$bvModal.hide("add-position");
          this.name = "";
          this.department = "";
          this.icon = "";
        },
      });
    },
  },
};
</script>
